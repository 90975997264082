import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight, isEqual } from 'lodash';
import {
  IS_COVER_IMAGE_ENABLED_PATH,
  IS_COVER_IMAGE_ENABLED_MOBILE_PATH,
  RELATED_POSTS_WIDGET_DEFAULTS,
  RELATED_POSTS_LAYOUT_PARAMS,
  RELATED_POSTS_LAYOUT_PARAMS_MOBILE,
  getLayoutName,
  SECTION_RELATED_POSTS,
} from '@wix/communities-blog-client-common';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { connect } from '../../../common/components/runtime-context';

import withDeviceType from '../../../common/hoc/with-device-type';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import {
  getUseMobileDisplaySettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import {
  getShouldUsePostListMobileSliderArrowColor,
  getShouldUsePostListMobileSliderBorderStyles,
} from '../../../common/selectors/mobile-settings-selectors';
import { getRelatedPosts } from '../../../common/selectors/post-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getPostCount } from '../../../common/store/post-count/post-count-selector';
import { RELATED_POSTS_ENTITY } from '../../actions/fetch-initial-data';
import { getRelatedPostsLayoutType } from '../../selectors/related-posts-layout-type';
import NoPostsEmptyState from '../no-posts-empty-state';

class PostList extends Component {
  canSeeMoreButton = () => false;

  render() {
    const {
      posts,
      isLoading,
      componentId,
      layoutType,
      layoutName,
      layoutOptions,
      hideCoverImage,
      shouldUsePostListMobileSliderArrowColor,
      shouldUsePostListMobileSliderBorderStyles,
      sliderArrowsPositions,
    } = this.props;
    const hasPosts = Boolean(posts.length);

    if (!isLoading && !hasPosts) {
      return <NoPostsEmptyState />;
    }

    if (!isEqual(this.layoutOptions, layoutOptions)) {
      this.layoutOptions = layoutOptions;
    }
    const postListRootClassNames = classNames({
      'mobile-slider-arrow-color': shouldUsePostListMobileSliderArrowColor,
      'mobile-slider-border-styles': shouldUsePostListMobileSliderBorderStyles,
    });

    return (
      <div className={postListRootClassNames}>
        <PostListProGallery
          className={
            layoutOptions.arrowsPosition === sliderArrowsPositions.ON_GALLERY
              ? 'arrows-on-gallery'
              : ''
          }
          layoutName={layoutName}
          layoutType={layoutType}
          entityCount={posts.length}
          layoutDefaults={RELATED_POSTS_WIDGET_DEFAULTS}
          domId={componentId}
          allPosts={posts}
          currentPagePosts={posts}
          isLoading={isLoading}
          showCreatePostAction={false}
          section={SECTION_RELATED_POSTS}
          canSeeMoreButton={this.canSeeMoreButton}
          layoutOptions={this.layoutOptions}
          hideCoverImage={hideCoverImage}
        />
      </div>
    );
  }
}

const mapRuntimeToProps = (state, { isMobile }, actions, host) => {
  const layoutType = getRelatedPostsLayoutType({ state, isMobile });

  const layoutName =
    getLayoutName(layoutType) || RELATED_POSTS_WIDGET_DEFAULTS.layoutName;
  const {
    sliderShowArrows,
    sliderArrowsSize,
    sliderAutoSlide,
    sliderPauseTime,
    sliderArrowsPosition,
    sliderArrowsColor,
    sliderLoop,
  } = getUseMobileLayoutSettings(state)
    ? RELATED_POSTS_LAYOUT_PARAMS_MOBILE
    : RELATED_POSTS_LAYOUT_PARAMS;

  return {
    postCount: getPostCount(state),
    posts: getRelatedPosts(state),
    layoutType,
    layoutName,
    isLoading: getIsEntityLoading(state, RELATED_POSTS_ENTITY),
    componentId: host.id,
    sliderArrowsPositions: sliderArrowsPosition.values,
    hideCoverImage: !getAppSettingsValue({
      state,
      key: getUseMobileDisplaySettings(state)
        ? IS_COVER_IMAGE_ENABLED_MOBILE_PATH
        : IS_COVER_IMAGE_ENABLED_PATH,
      fallback: true,
    }),
    layoutOptions: {
      showArrows: getAppSettingsValue({
        state,
        key: sliderShowArrows.appSettingsPath,
        fallback: sliderShowArrows.defaultValue,
      }),
      arrowsSize: getAppSettingsValue({
        state,
        key: sliderArrowsSize.appSettingsPath,
        fallback: sliderArrowsSize.defaultValue,
      }),
      autoSlide: getAppSettingsValue({
        state,
        key: sliderAutoSlide.appSettingsPath,
        fallback: sliderAutoSlide.defaultValue,
      }),
      pauseTime: getAppSettingsValue({
        state,
        key: sliderPauseTime.appSettingsPath,
        fallback: sliderPauseTime.defaultValue,
      }),
      arrowsPosition: getAppSettingsValue({
        state,
        key: sliderArrowsPosition.appSettingsPath,
        fallback: sliderArrowsPosition.defaultValue,
      }),
      arrowsColor: getAppSettingsValue({
        state,
        key: sliderArrowsColor.appSettingsPath,
        fallback: sliderArrowsColor.defaultValue,
      }),
      loop: getAppSettingsValue({
        state,
        key: sliderLoop.appSettingsPath,
        fallback: sliderLoop.defaultValue,
      }),
    },
    shouldUsePostListMobileSliderArrowColor:
      getShouldUsePostListMobileSliderArrowColor(state),
    shouldUsePostListMobileSliderBorderStyles:
      getShouldUsePostListMobileSliderBorderStyles(state),
  };
};

export default flowRight(withDeviceType, connect(mapRuntimeToProps))(PostList);
