import {
  SEE_ALL_LINK_TEXT_MOBILE_DESIGN_PARAMS,
  SEE_ALL_LINK_LAYOUTS,
  SEE_ALL_LINK_LAYOUT_PATH,
  SEE_ALL_LINK_TEXT_DESIGN_PARAMS,
  SEE_ALL_LINK_BUTTON_DESIGN_PARAMS,
  SEE_ALL_LINK_LAYOUT_MOBILE_PATH,
  SEE_ALL_LINK_BUTTON_MOBILE_DESIGN_PARAMS,
  SEE_ALL_LINK_MOBILE_DESIGN_TOGGLE,
} from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { AppState } from '../../common/types';

export const getUseMobileSettings = (state: AppState) =>
  getIsMobile(state) &&
  getIsMobileSettingEnabled(
    state,
    SEE_ALL_LINK_MOBILE_DESIGN_TOGGLE.appSettingsPath,
  );

export const getSeeAllLinkLayout = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: getUseMobileSettings(state)
      ? SEE_ALL_LINK_LAYOUT_MOBILE_PATH
      : SEE_ALL_LINK_LAYOUT_PATH,
    fallback: SEE_ALL_LINK_LAYOUTS.text,
  });

export const getIsSeeAllLinkLayoutText = (state: AppState) =>
  getSeeAllLinkLayout(state) === SEE_ALL_LINK_LAYOUTS.text;

export const getIsSeeAllLinkUppercase = (state: AppState) => {
  const useMobileSettings = getUseMobileSettings(state);
  const seeAllLinkLayout = getSeeAllLinkLayout(state);

  const params = {
    [SEE_ALL_LINK_LAYOUTS.text]: useMobileSettings
      ? SEE_ALL_LINK_TEXT_MOBILE_DESIGN_PARAMS
      : SEE_ALL_LINK_TEXT_DESIGN_PARAMS,
    [SEE_ALL_LINK_LAYOUTS.button]: useMobileSettings
      ? SEE_ALL_LINK_BUTTON_MOBILE_DESIGN_PARAMS
      : SEE_ALL_LINK_BUTTON_DESIGN_PARAMS,
  }[seeAllLinkLayout];

  return getAppSettingsValue({
    state,
    key: params.uppercase.appSettingsPath,
    fallback: params.uppercase.defaultValue,
  });
};
