import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import PostList from '../post-list';
import SeeAllLink from '../see-all-link/see-all-link';
import styles from './related-posts.scss';

const RelatedPosts = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div className={isMobile ? styles.mobile : undefined}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('related-posts.title')}</h2>
        <SeeAllLink />
      </div>
      <div className={styles.list}>
        <PostList />
      </div>
    </div>
  );
};

export default RelatedPosts;
